import * as React from 'react';
import { graphql, HeadFC, PageProps } from 'gatsby';
import Layout from '../layouts/Layout';
import SEO from '../components/SEO/SEO';
import ContentRepeater from '../components/ContentRepeater/ContentRepeater';
import Hero from '../components/Hero/Hero';
import { ContentfulPage } from '../types/contentful/PageTypes/ContentfulPage/ContentfulPage';
import { NestingSurveyProvider } from '../context/NestingSurveyContext';
import { SiteMetadata } from '../types/gatsby/SiteMetadata';

interface Data {
    site: SiteMetadata;
    contentfulPage: ContentfulPage;
}

interface Props extends PageProps {
    data: Data
}

const NestingSurveyPage = ({ data: { contentfulPage } }: Props) => {
    return (
        <Layout>
            <NestingSurveyProvider>
                <Hero
                    title={contentfulPage?.pageTitle}
                    description={contentfulPage?.pageDescription}
                    image={contentfulPage?.pageImage}
                />
                <ContentRepeater content={contentfulPage?.content} />
            </NestingSurveyProvider>
        </Layout>
    );
};


export default NestingSurveyPage;

// gatsby page query
export const pageQuery = graphql`
    query NestingSurveyPageQuery {
        site {
            siteMetadata {
                title
                description
                url
                image
            }
        }
        contentfulPage(internalName: {eq: "Nesting Survey Page"}) {
            ...pageFields
            ...pageContent
        }
    }
`;

export const Head: HeadFC = ({ location, data }) => {
    const seoData = data as Data;

    return (
        <SEO data={seoData} location={location} />
    );
};
